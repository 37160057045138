import { ICreateTBTPRequest, ICreateTBTPRequestV3 } from './../models/request/tbtp/ICreateTBTPRequest';
import { IBaseDeleteRequestModel } from "../models/request/base/deleteRequest";
import { sf_mucthuphi_select_by_dotgui_rq, sf_mucthuphi_select_rq } from "../models/request/mucthuphi/sf_mucthiphi_select_rq";
import { sf_tbtp_add_hachtoan_rq } from "../models/request/tbtp/sf_tbtp_add_hachtoan_rq";
import { sf_tbtp_add_khoannop_rq } from "../models/request/tbtp/sf_tbtp_add_khoannop_rq";
import { sf_tbtp_auto_hachtoan_rq } from "../models/request/tbtp/sf_tbtp_auto_hachtoan_rq";
import { sf_tbtp_insert_rq } from "../models/request/tbtp/sf_tbtp_insert_rq";
import { sf_tbtp_delete_rq } from "../models/request/tbtp/sf_tbtp_delete_rq";
import { sf_tbtp_pdf_create_rq } from "../models/request/tbtp/sf_tbtp_pdf_create_rq";
import { sf_tbtp_remove_hachtoan_rq } from "../models/request/tbtp/sf_tbtp_remove_hachtoan_rq";
import { sf_tbtp_remove_khoannop_rq } from "../models/request/tbtp/sf_tbtp_remove_khoannop_rq";
import { sf_tbtp_select_by_hocsinh_rq } from "../models/request/tbtp/sf_tbtp_select_by_hocsinh_rq";
import { sf_tbtp_send_rq } from "../models/request/tbtp/sf_tbtp_send_rq";
import { apiClient } from "./apiClient";
import { sf_tbtp_payment_create_rq } from '../models/request/tbtp/sf_tbtp_payment_create_rq';
import { sf_tbtp_send_remind_rq } from '../models/request/tbtp/sf_tbtp_send_remind_rq';
import { sf_tbtp_select_rq, sf_tbtp_select_rq_dotgui } from '../models/request/tbtp/sf_tbtp_select_rq';
import { sf_mucphaithu_import_khoanthu_rq } from '../models/request/muc-phai-thu/sf_mucphaithu_import_rq';
import { sf_tbtp_import_hocsinh_rq } from '../models/request/tbtp/sf_tbtp_import_hocsinh_rq';

export const SF_TBTP_SELECT_DUKIEN_API = "sf_tbtp/select-dukien";


export const SF_TBTP_SELECT_API = "sf_tbtp/select";
export const SF_TBTP_INSERT_API = "sf_tbtp/insert";
export const SF_TBTP_DELETE_API = "sf_tbtp/delete";

export const ST_TBTP_SELECT_KHOANNOP_API = "sf_tbtp_khoannop/select_by_tbtp";
export const ST_TBTP_SELECT_HACHTOAN_API = "sf_tbtp_khoannop/select_hachtoan";


export const ST_TBTP_ADD_HACHTOAN_API = "sf_tbtp/add_hachtoan";
export const ST_TBTP_REMOVE_HACHTOAN_API = "sf_tbtp/remove_hachtoan";

export const ST_TBTP_ADD_KHOANNOP_API = "sf_tbtp/add_khoannop";
export const SF_TBTP_REMOVE_KHOANNOP_API="sf_tbtp/remove_khoannop";

export const SF_TBTP_CREATE_PDF_API="sf_tbtp/create_pdf";

export const SF_TBTP_SELECT_BY_HOCSINH_API="sf_tbtp/select_by_hocsinh";
export const SF_TBTP_SELECT_BY_ID_API="sf_tbtp/select_by_id";

export const ST_TBTP_AUTO_HACHTOAN_API="sf_tbtp/auto_hachtoan";
export const SF_TBTP_SEND_TBTP_API="sf_tbtp/send_tbtp";

export const SF_TBTP_CREATE_PAYMENT_API="sf_tbtp/create_payment";
export const SF_TBTP_SEND_REMIND_TBTP_API="sf_tbtp/send_remind_tbtp";

export const SF_TBTP_PRINT_API = "sf_tbtp/print-pdf";


export const tbtpApi = {
    select_du_kien: (request:sf_mucthuphi_select_rq)=>{
        return apiClient.post(`${SF_TBTP_SELECT_DUKIEN_API}`, request)
    },
    select: (request: sf_tbtp_select_rq_dotgui) => {
        return apiClient.post(`${SF_TBTP_SELECT_API}`, request)
    },
    reportAlpha: (request: sf_mucthuphi_select_by_dotgui_rq) => {
        return apiClient.post(`sf_tbtp/report-alpha`, request)
    },
    insert: (data: sf_tbtp_insert_rq) => {
        return apiClient.post(`${SF_TBTP_INSERT_API}`, data)
    },
    insertV2: (data: ICreateTBTPRequest) => {
        return apiClient.post(`${SF_TBTP_INSERT_API}`, data)
    },
    insertV3: (data: ICreateTBTPRequestV3) => {
        return apiClient.post(`${SF_TBTP_INSERT_API}`, data)
    },
    delete: (data: sf_tbtp_delete_rq) => {
        // const model: IBaseDeleteRequestModel = {
        //     ids: data.ids
        // }
        return apiClient.post(`${SF_TBTP_DELETE_API}`, data)
    },
    select_khoannops: (sf_tbtp_id: number) => apiClient.post(`${ST_TBTP_SELECT_KHOANNOP_API}?sf_tbtp_id=${sf_tbtp_id}`),
    select_hachtoans: (sf_tbtp_id: number) => apiClient.post(`${ST_TBTP_SELECT_HACHTOAN_API}?sf_tbtp_id=${sf_tbtp_id}`),
    add_hachtoan: (data: sf_tbtp_add_hachtoan_rq) => apiClient.post(ST_TBTP_ADD_HACHTOAN_API, data),
    add_khoannop: (data: sf_tbtp_add_khoannop_rq) => apiClient.post(ST_TBTP_ADD_KHOANNOP_API, data),
    remove_hachtoan: (data: sf_tbtp_remove_hachtoan_rq) => apiClient.post(ST_TBTP_REMOVE_HACHTOAN_API, data),
    remove_khoannop: (data: sf_tbtp_remove_khoannop_rq) => apiClient.post(SF_TBTP_REMOVE_KHOANNOP_API, data),
    create_pdf: (data: sf_tbtp_pdf_create_rq) => apiClient.post(SF_TBTP_CREATE_PDF_API, data),
    select_by_hocsinh: (data: sf_tbtp_select_by_hocsinh_rq) => apiClient.post(SF_TBTP_SELECT_BY_HOCSINH_API, data),
    auto_hachtoan: (data: sf_tbtp_auto_hachtoan_rq) => apiClient.post(`${ST_TBTP_AUTO_HACHTOAN_API}`, data),
    send_tbtp: (data: sf_tbtp_send_rq) => apiClient.post(`${SF_TBTP_SEND_TBTP_API}`, data),
    select_by_id: (sf_tbtp_id: number) => apiClient.post(`${SF_TBTP_SELECT_BY_ID_API}/sf_tbtp_id`,sf_tbtp_id),
    create_payment: (data: sf_tbtp_payment_create_rq) => apiClient.post(SF_TBTP_CREATE_PAYMENT_API, data),
    send_remind_tbtp: (data: sf_tbtp_send_remind_rq) => apiClient.post(`${SF_TBTP_SEND_REMIND_TBTP_API}`, data),
    print_pdf_file: (data: sf_tbtp_delete_rq) => apiClient.post(`${SF_TBTP_PRINT_API}`, data),
    getSheets: (file_name_uploaded: string) => apiClient.post(`sf_tbtp/get_sheets?file_name_uploaded=${file_name_uploaded}`),
    readFiles_import_hocsinh: (rq: sf_tbtp_import_hocsinh_rq) => apiClient.post(`sf_tbtp/read_file_tbtp`, rq),

}

